<script setup lang="ts">
import type { IReminder } from '@critical-dates'
import type { IconName } from '#build/icons'

type Props = {
  reminder: IReminder
}
const props = defineProps<Props>()

const dayjs = useDayjs()
const { createDivisionURL, dateFormat } = useDivisions()

//

const isNext7Days = computed(() => {
  return useIsBeforeDate(props.reminder?.reminderDate, 7, 'day')
})

const isNext30Days = computed(() => {
  return (
    useIsBeforeDate(props.reminder?.reminderDate, 30, 'day') &&
    !isNext7Days.value
  )
})

const isUpcoming = computed(() => {
  return !isNext30Days.value && !isNext7Days.value
})
</script>

<template>
  <div>
    <NuxtLink
      role="button"
      class="group relative flex flex-row gap-x-4 rounded py-2 pl-2 pr-4 ring-2 ring-transparent transition-colors"
      :class="{
        'hover:bg-red/15 hover:ring-red pl-4': isNext7Days,
        'hover:bg-warning/20 hover:ring-warning pl-4': isNext30Days,
        'hover:bg-gray-750 hover:ring-gray-600': isUpcoming,
      }"
      :to="createDivisionURL(`/lease/${reminder?.leaseDetails?.id}`)"
    >
      <i class="absolute bottom-0 right-3 top-0 hidden w-4 group-hover:flex">
        <Icon
          name="chevron-right"
          filled
          class="flex self-center text-2xl"
          :class="{
            'group-hover:text-red': isNext7Days,
            'group-hover:text-warning': isNext30Days,
            'group-hover:text-white': isUpcoming,
          }"
        />
      </i>
      <!-- Date -->
      <div
        class="flex flex-col items-center justify-center"
        :class="{
          'text-red': isNext7Days,
          'text-warning': isNext30Days,
        }"
      >
        <span class="text-center text-sm md:w-36"> Reminder date </span>

        <span class="py-1 text-4xl font-extrabold">
          {{ dayjs(reminder.reminderDate).format('DD') }}
        </span>

        <span class="text-2xl uppercase">
          {{ dayjs(reminder.reminderDate).format('MMM') }}
        </span>
      </div>

      <!-- Lease Name -->
      <div class="flex flex-col gap-2">
        <p
          class="font-semibold"
          :class="{
            'group-hover:text-red': isNext7Days,
            'group-hover:text-warning': isNext30Days,
            'group-hover:text-white': isUpcoming,
          }"
        >
          {{ reminder?.leaseDetails?.address }}
        </p>

        <!-- Trigger Type -->
        <p class="inline-flex items-center gap-2">
          <span class="flex w-11 justify-end">
            <Icon
              :name="camel(reminder.reminderType) as IconName"
              class="text-xl text-gray-500"
              :class="{
                'text-red': isNext7Days,
                'text-warning': isNext30Days,
                'text-white': isUpcoming,
              }"
            />
          </span>
          {{ reminder.reminderPeriod }}
          <span
            class="border-gray-750 inline-flex gap-1 rounded-full border bg-gray-800 px-2 py-0.5 text-sm"
            :class="{
              'border-red group-hover:bg-red group-hover:border-transparent group-hover:text-red-100':
                isNext7Days,
              'border-warning group-hover:bg-warning group-hover:text-gray-850 group-hover:border-transparent':
                isNext30Days,
              'group-hover:bg-gray-850 group-hover:border-transparent group-hover:text-white':
                isUpcoming,
            }"
          >
            <span
              class="font-bold"
              :class="{
                'text-red group-hover:text-white': isNext7Days,
                'text-warning group-hover:text-gray-850': isNext30Days,
                'text-white': isUpcoming,
              }"
            >
              {{
                capitalize(title(reminder.reminderType) ?? '')?.replace(
                  '_',
                  ' ',
                )
              }}
            </span>
            <span class="text-gray-500">on</span>
            <time>
              {{ dayjs(reminder.leaseDate).format(dateFormat) }}
            </time>
          </span>
        </p>

        <!-- title -->
        <p class="flex gap-1 text-sm">
          <span class="mt-0.5 w-11 text-right text-xs text-gray-500">
            Title:
          </span>
          {{ reminder.title }}
        </p>

        <!-- Lease name -->
        <p class="flex gap-1 text-sm">
          <span class="mt-0.5 w-11 text-right text-xs text-gray-500">
            Lease:
          </span>
          {{ reminder.leaseDetails.name }}
        </p>

        <!-- Recipients -->
        <p class="flex gap-1 text-sm">
          <span class="mt-0.5 w-11 text-right text-xs text-gray-500">To:</span>
          {{ useGetRecipients(reminder?.recipients) }}
        </p>
      </div>
    </NuxtLink>
  </div>
</template>
